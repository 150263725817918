<template>
  <div>
    <!-- Hero -->
    <div class="bg-body-light">
      <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
          <div class="flex-grow-1">
            <h1 class="h3 fw-bold mb-2">Data Naskah Saya</h1>
            <h2 class="fs-base lh-base fw-medium text-muted mb-0">
              Beri affiliator naskahmu agar mudah dalam penjualan!
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->

    <div class="content">
      <input
        type="text"
        class="form-control"
        placeholder="Cari Judul Naskah"
        v-model="search"
        @input="filterData"
      />

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-vcenter">
          <thead>
            <tr>
              <th class="text-center" style="width: 300px !important;"><i class="fa fa-qrcode"></i></th>
              <th>Judul Naskah</th>
              <th>Penulis</th>
              <th>Harga</th>
              <th>Affiliate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(market, i) in filteredMarkets" :key="i">
              <td class="text-center">
                <div class="d-flex flex-column align-items-center">
                  <vue-qr :text="market.ebook" logoSrc="/qrlogo.png" :size="100" />
                </div>
              </td>
              <td class="fw-semibold fs-sm">{{ market.title }}</td>
              <td class="fs-sm">{{ market.author }}</td>
              <td class="fs-sm">Rp {{ formatPrice(market.price) }}</td>
              <td>
                <button 
                  v-if="market.is_affiliate"
                  class="btn btn-sm btn-success"
                  disabled
                >
                  {{ market.percentage }}%
                </button>
                <button v-else class="btn btn-sm btn-primary" @click="openAffiliateModal(market)">
                  Affiliate
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <vs-pagination v-model="page" :length="totalPages" @input="getUserMarkets" />
    </div>

    <br /><br /><br />

    <div class="heading-affiliator mt-4 mb-4">
      <h3 class="text-center">Daftar naskah yang kamu affiliasi</h3>
      <p class="text-center">Sebar link di banyak platform kamu dan dapatkan komisi dari penjualan</p>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-vcenter">
        <thead>
          <tr>
            <th class="text-center">Link Penjualan</th>
            <th class="text-center">Judul Naskah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(affiliate, i) in affiliates" :key="i">
            <!-- <td class="text-center">{{ affiliate.slug }}/{{ affiliate.link_penjualan }}</td> -->
            <td class="text-center">
              <span>{{ getShortUrl(affiliate.slug, affiliate.link_penjualan) }}</span>
              <button class="btn btn-sm-secondary ms-2" @click="copyToClipboard(affiliate.slug, affiliate.link_penjualan)">
                Copy
              </button>
            </td>
            <td class="text-center">{{ affiliate.market_title }}</td>
          </tr>
        </tbody>
      </table>
    </div>


    <!-- Modal Affiliate -->
    <v-dialog v-model="showAffiliateModal" max-width="500px">
      <v-card>
        <v-card-title> Tambah Affiliate </v-card-title>
        <v-card-text v-if="selectedMarket">
          <p><strong>Judul:</strong> {{ selectedMarket.title || "Tidak tersedia" }}</p>
          <p><strong>Tanggal Publish:</strong> {{ selectedMarket.published_at || "Belum tersedia" }}</p>
          <p><strong>Harga:</strong> Rp {{ formatPrice(selectedMarket.price) }}</p>
          <v-text-field
            v-model="commission"
            label="Persentase Komisi (%)"
            type="number"
            min="0"
            max="100"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" @click="showAffiliateModal = false">Batal</v-btn>
          <v-btn color="primary" @click="submitAffiliate">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import axios from "axios";

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      loading_data: false,
      search: "",
      page: 1,
      markets: [],
      affiliates: [],
      filteredMarkets: [],
      totalPages: 1,
      showAffiliateModal: false,
      selectedMarket: null,
      commission: 0,
    };
  },
  methods: {
    getShortUrl(slug, link) {
      return `localhost:8080/${slug}/${link}`
    },

    copyToClipboard(slug, link) {
      const shortUrl = this.getShortUrl(slug, link)
      navigator.clipboard.writeText(shortUrl)
        .then(() => {
          alert("Link berhasil disalin: " + shortUrl)
        })
        .catch(err => {
          console.error("Gagal menyalin link: " + err)
        })
    },
    async getUserMarkets() {
      this.loading_data = true;
      var token = localStorage.getItem("access_token");

      try {
        const response = await axios.get("https://my.qrcbn.com/api/user/affiliate", {
          headers: { Authorization: "Bearer " + token },
        });

        console.log("Data dari API", response.data)

        if (response.data.success) {
          this.markets = response.data.data.map(market => ({
            ...market,
            is_affiliate: market.is_affiliate || false,
            percentage: market.percentage || 0,
          }));
          this.filteredMarkets = this.markets;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading_data = false;
      }
    },
    async getMeAffiliate() {
      this.loading_data = true;
      var token = localStorage.getItem("access_token");

      try {
        const response = await axios.get("https://my.qrcbn.com/api/reseller/list", {
          headers: { Authorization: "Bearer " + token },
        });

        console.log("Data dari API", response.data);

        if (response.data.success) {
          this.affiliates = response.data.data.map(market => ({
            ...market,
            naskah_title: market.market_title || "",
            link_penjualan: market.link_penjualan,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading_data = false;
      }
    },
    filterData() {
      this.filteredMarkets = this.markets.filter((market) =>
        market.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    openAffiliateModal(market) {
      console.log("market yang dipillih", market)
      this.selectedMarket = market || {};
      this.commission = 0;
      this.showAffiliateModal = true;
    },
    async submitAffiliate() {
      if (!this.selectedMarket) {
        console.error("Error: Tidak ada naskah yang dipilih.");
        return;
      }

      const token = localStorage.getItem("access_token");

      const requestData = {
        naskah_id: this.selectedMarket.id,
        percentage: this.commission,
      };

      console.log("datanya ", requestData);

      try {
        const response = await axios.post(
          "https://my.qrcbn.com/api/user/affiliate/add",
          requestData,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        if (response.status === 201) {
          alert("Produk berhasil didaftarkan ke affiliate!");
          this.showAffiliateModal = false;
          this.getUserMarkets();
        }
      } catch (error) {
        console.error(
          "Gagal !",
          (error.response && error.response.data) ? error.response.data : error
        )
        alert((error.response && error.response.data && error.response.data.message)
            ? error.response.data.message
            : "Terjadi kesalahan silahkan coba lagi"
        )
      }
    },

    formatPrice(price) {
      return price ? price.toLocaleString() : "0";
    },
  },
  mounted() {
    this.getUserMarkets();
    this.getMeAffiliate();
  },
};
</script>

<style scoped>
@import "../../../public/oneui/assets/css/oneui.min.css";
</style>